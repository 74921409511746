import { InjectionToken, Injector, TemplateRef, Type } from "@angular/core";
import { AsyncSubject, BehaviorSubject, combineLatest, Observable } from "rxjs";

export const templateToken = new InjectionToken<TemplateRef<HTMLTemplateElement>>('');
export const dataToken = new InjectionToken<any>('');


export interface ComponentItemObject {
    label?: string
    component?: Type<any>
    $injector?: ComponentInjector
    $validator?: Observable<boolean>
    // getInjector(): Injector
}

export interface ComponentItem extends ComponentItemObject {
    new?(label?: string, component?: ComponentItemObject): ComponentItemObject;
}

export class ComponentItem implements ComponentItem {
    // item: ComponentItemObject;
    label: string;
    
    $injector: ComponentInjector;
    injector:Injector;
    constructor(label?: string, component?: ComponentItemObject, options?: FormItemOptions){
        // this.item = item;
        this.label = label
        this.component = component.component;
        this.injector= Injector.create({
            providers: [
                { provide: dataToken, useValue: component.$injector.data}
            ],
            parent: component.$injector.parent
        })
    }

    getInjector(): Injector {
        return this.injector;
    }
}

export interface ComponentInjector {
    data: any
    parent?: Injector
    responder?: AsyncSubject<any>
}

export interface ComponentTemplateItem {
    label: string
}

export interface ComponentList {
    path?: string
    components?: Array<ComponentItem | ComponentListItem>
    injector?: any
}

//old landing section implementation
export interface ComponentListItem {
    label?: string;
    component?: Type<any>;
    data?: any;
}


export interface FormOptions {
    asView?: boolean,
    header?: FormHeaderOptions,
    footer?: FormFooter
}

export interface FormError {
    blocking: boolean,
    message: string,
    type: 'required' | 'validator',
    level: 'warn' | 'error',
}

export interface FormItemOptions {
    stepper?: FormStepper
    footer?: FormFooter
    next?: ButtonOptions //Type 'next' or 'submit'
    back?: ButtonOptions //Type 'skip' or 'back'
}

export interface FormStepper {
    label: string
    active?: boolean
    complete?: boolean
    error?: FormError
    icon?: string| false
    validator?: any
}

export interface FormFooter {
    back?: ButtonOptions
    next?: ButtonOptions
    skip?: ButtonOptions
    sumbit?: ButtonOptions
    validator?: Observable<boolean>
}

export interface FormHeaderOptions {
    title?: boolean,
    stepper?: boolean,
    dismissable?: boolean
}

export interface ButtonOptions {
    icon?: string
    label?: string
    type?: "next" | "back" | "skip" | "submit"
    validate?: Observable<boolean>
    onClick?: Function
}


// Multi-step Form Builder

export interface MultiFormObject {
    label: string
    active: FormItem
    processing: boolean
    complete: boolean
    error?: FormError
    overlay?: boolean;
    components: FormItem[]
    options?: FormOptions
    getActive(): FormItem
    getSteppers(): Observable<FormStepper[]>
    getFooters():  Observable<FormFooter[]>
    setActive(label:string): void
    
}

// /**
//  * @param {string} label - The label to display within header
//  */

export interface MultiForm extends MultiFormObject {
    new(label: string, forms: Array<FormItem>,  options?: FormOptions): MultiFormObject;    
}

export class MultiForm implements MultiForm {
    label: string;
    active: FormItem = null;
    components: Array<FormItem>;
    options: FormOptions;


    processing: boolean = false;
    complete: boolean = false;
    error: FormError = null;
    overlay: boolean;

    steppers: BehaviorSubject<Observable<FormStepper>[]> = new BehaviorSubject<Observable<FormStepper>[]>([]);
    $steppers: Observable<FormStepper>[];
    $footers: Observable<FormFooter>[];
    
    submitter: AsyncSubject<boolean> = new AsyncSubject<boolean>();
    $onSubmit: Observable<boolean> = this.submitter.asObservable();
    $unsubscribe: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(label: string, forms: Array<FormItem>, options?: FormOptions) {
        this.components = forms;
        this.label = label;
        this.options = options;
        this.init();
    }

    init(): void {
        let steps = [];
        let footers = [];

        this.components.forEach((form: FormItem) => {
            let step = form.getStepper();
            let footer = form.getFooter();
            steps.push(step);
            footers.push(footer);
        });

        this.$steppers = steps;
        this.$footers = footers;

        this.active = this.components[0];
        // this.active.setActive(true);
    }

    getActive(): FormItem {
        return this.active
    }

    setActive(label: string):void {
        // console.log(label);
        this.components.map(res => {
            if(res.label == label){
                this.active = res;
            }
        })
    }

    getSteppers(): Observable<FormStepper[]>{
        return combineLatest(this.$steppers);
    }

    getFooters(): Observable<FormFooter[]>{
        return combineLatest(this.$footers);
    }
    
}

export interface FormItemObject {
    options: FormItemOptions,
    getStepper(): FormStepper
    getFooter(): FormFooter
    setActive(): void;
    setComplete(): void;
    bind(): BehaviorSubject<any>
}

export interface FormItem {
    new(component?: ComponentItemObject, options?: FormItemOptions): FormItemObject;
}

export class FormItem {
    public label: string;
    public form: ComponentItemObject;
    public component: Type<any>;
    public injector: Injector;

    private options: FormItemOptions;
    private stepper: FormStepper;
    private footer: FormFooter = {};
    private $stepper: BehaviorSubject<FormStepper> = new BehaviorSubject<FormStepper|any>({});
    private $footer: BehaviorSubject<FormFooter> = new BehaviorSubject<FormFooter | any>({});
    protected formItem = new BehaviorSubject<any>({});

    constructor(component: ComponentItemObject, options: FormItemOptions){
        this.form = component;
        this.label = component.label;
        this.component = component.component;
        this.options = options;
        this.init(options);
    }

    private init(options: FormItemOptions): void {
        
        this.buildInjector();

        if(this.options && this.options.stepper){
            this.buildStepper();
        }

        this.buildFooter();

        // this.footer = options.footer;
        
        // this.$footer.next(options.footer);
    }

    private buildInjector(): void {
        if(this.form.$injector){
            let inj = Injector.create({
                providers: [
                    { provide: dataToken, useValue: this.form.$injector.data}
                ],
                parent: this.form.$injector.parent
            })
    
            this.injector = inj;
        }
    }

    private buildStepper(): void {
        this.stepper = {
            label: this.options.stepper.label,
            active: false,
            complete: false,
            validator: this.form.$validator,
            error: null,
            icon: (this.options.stepper.icon) ? this.options.stepper.icon : false
        }
        this.$stepper.next(this.stepper);
    }

    private buildFooter(): void {

        if(this.options && this.options.next){
            // console.log(this.footer);
            this.footer.next = this.options.next;
            this.footer.next.validate = this.form.$validator;
        }

        if(this.options && this.options.back){
            this.footer.back = this.options.back;
        }

        this.$footer.next(this.footer);
    }

    public getStepper(): Observable<FormStepper> {
        return this.$stepper.asObservable();
    }

    public getFooter(): Observable<FormFooter> {
        return this.$footer.asObservable();
    }

    public setActive(bool: boolean): void {
        this.stepper.active = bool;
        this.$stepper.next(this.stepper);
    }
    public setError(err:FormError): void {
        this.stepper.error = err;
        this.$stepper.next(this.stepper);
    }
    public setComplete(bool: boolean): void {
        this.stepper.complete = true;
        this.$stepper.next(this.stepper);
    }

    public bind(stepper?: FormStepper, footer?: FormFooter): void {
        
        if (footer) {
            this.$footer.next(footer);
        } else if (stepper) {
            this.$stepper.next(stepper);
        }
        
        return

    }

}

