import { Injectable, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { PlatformStateService } from './platform-state.service';

declare let gtag:Function;

@Injectable({
  providedIn: 'root'
}) 

export class AnalyticEventService implements OnInit {

  userId: string

  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private platform: PlatformStateService
  ) { 
    this.router.events.pipe(
      filter((event):event is NavigationEnd => event instanceof NavigationEnd),
      //tap(x => console.log(x)),
      map(() => this.route),
      map((route) => {
          while (route.firstChild) { route = route.firstChild; }
          //console.log(route)
          return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      //console.log(event);
      
      if(!event.res){
        this.title.setTitle('Fanmire');
        return
      }
      
      if(event.res.title){
        this.title.setTitle(event.res.title + ' | Fanmire');
      }
      if(event['description']){
        this.meta.updateTag({ name: 'description', content: event['description'] });
      }
      if(event['keywords']){
        this.meta.updateTag({ name: 'keywords', content: event['keywords'] });
      }
        
    });
  }
  ngOnInit(): void {
    this.getUserId();
  }

  updateMeta(options?: string) {
    if (!options) {
        
    } else {
      if(options['title']){
        this.title.setTitle(options['title'] + ' | Fanmire');
      }
      if(options['description']){
        this.meta.updateTag({ name: 'description', content: options['description'] });
      }
      if(event['keywords']){
        this.meta.updateTag({ name: 'keywords', content: options['keywords'] });
      }
    }
  }

  getUserId() {
    this.userId = this.platform.getLoggedInUser();
    if (this.userId != "") {
      gtag('event', 'setUserId', {
          userId: this.userId, 
      });
    }
  }

  public eventEmitter( 
    eventName: string, 
    eventCategory: string, 
    eventAction: string, 
    eventLabel: string = null,  
    eventValue: number = null ){ 
      gtag('event', eventName, { 
        event_category: eventCategory, 
        event_label: eventLabel, 
        eventAction: eventAction, 
        value: eventValue,
        user_id: this.getUserId()
      })
    }
  
  /*Content & Engagement Events */
  emitSignUp(referrer: string): void  {
    this.eventEmitter('sign_up', 'signup', 'sign_up', referrer, 1);
  }

  emitLogIn(log_in_page: string = "user"): void  {
    this.eventEmitter('login', 'login', 'login', log_in_page, 1);
  }

  emitLogOut(log_out_page: string = "navigation"): void  {
    this.eventEmitter("logout", "engagement", "logout", log_out_page, 1);
  } 

  emitPassowrdChange(){
    this.eventEmitter("change_password", "engagement", "change_password", "settings", 1);
  }

  emitSearch(keyword: string): void  {
    this.eventEmitter("search", "engagement", "search", keyword, 1);
  }

  emitEntourageJoin(group_name: string): void  {
    this.eventEmitter("join_entourage", "engagement", "join_entourage", group_name, 1);
  }

  emitEntourageExit(group_name: string): void  {
    this.eventEmitter("join_entourage", "engagement", "join_entourage", group_name, 1);
  }
 

  emitCommentToggle(post_user_name: string): void  {
    this.eventEmitter("toggle_comment", "content", "toggle_comment", post_user_name, 1);
  }

  emitContentLike(post_user_name: string): void  {
    this.eventEmitter("toggle_comment", "content", "toggle_comment", post_user_name, 1);
  }

  emitContentBookmark(post_user_name: string): void{
    this.eventEmitter("bookmark", "content", "bookmark", post_user_name, 1)
  }

  emitContentPurchase(post_user_name: string, post_price:number): void  {
    this.eventEmitter("content_purchase", "content", "content_purchase", post_user_name, post_price);
  }

  /*Subscription & Payment Events */
  
  emitUnsubscribe(member_user_name: string): void  {
    this.eventEmitter("unsubscribe", "subscription", "unsubscribe", member_user_name, 1);
  }

  emitSubscriptionPriceViewed(user_name: string): void  {
    this.eventEmitter("view", "ecommerce", "add_payment_info", user_name, 1);
  }

  emitAddCardOnFile(user_name: string): void  {
    this.eventEmitter("add_payment_info", "ecommerce", "add_payment_info", user_name, 1);
  }

  emitDeleteCardOnFile(): void  {
    this.eventEmitter("delete_payment_info", "ecommerce", "delete_payment_info", "settings", 1);
  }

  emitSubscriptionPurchase(fan_user_name: string, amt: number): void {
    this.eventEmitter("subscription_purchase", "subscription", "subscription_purchase", fan_user_name, amt);
  }

  emitCreatorApplied(user_name: string){
    this.eventEmitter('apply', 'apply', 'platformApply', user_name, 1);
  }

  emitCreatorPayoutAdded() {
    this.eventEmitter('add_payout_info', 'add_payout_info', 'AddPayout', 'add_payout_info', 1);
  }

  
}
