export const ISODateFormatRegex = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/

export function isIsoDate(str:string):boolean {
    // console.log(ISODateFormatRegex.test(str))
    // if (!ISODateFormatRegex.test(str)) return false;
    const d: any | Date = new Date(str);
    return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date 
}

export function toDateObject(date:string | Date): Date {
    // console.log(isIsoDate(date as string))
    if(!(date instanceof Date)){
        return new Date(date)
    } else if (date instanceof Date){
        return new Date(date)
    }

    return null
    
}

export function naturalizeDates(proterty: string ,value: any): any {
    if(proterty.includes('date')){
        value = toDateObject(value);
        // console.log('key:', proterty, 'object:', value)
        
        // return toDateObject(value)
    }
}