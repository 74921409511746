import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FileObject, FileModelService, MediaObject, ModelStatus } from './file-model.service'
import { UiService } from './ui.service';
import { MediaHTTPClient, HTTPResolvableError } from './http.service';
import { promise } from 'protractor';
// import { UiService } from './ui.service';

export interface MediaChunk {
  id: string,       //Unique ID
  media_id: string  //ID of FileObject
  index: number,    //Current chunk index
  legnth: number,   //Chunk size in Bytes
  content: string,  //Base64 data
  mime: string,     //MIME type
  type: string      //File extention type
}

export interface Media {
  id: string,
  date: Date
}

export interface MediaError extends Media, HTTPResolvableError {
  canceled?: boolean
}

export interface MediaMetaInfo extends Media {
  type: string,
  mime: string,
  size: number //size in bytes
}

export interface MediaRequestSource extends Media, MediaMetaInfo {
  content: string | ArrayBuffer
}

export interface MediaRequest extends Media, MediaMetaInfo {
  length: number
}

export interface MediaRequestResponse extends MediaRequest {
  res: string;
  status: string;
}

// move to http websocket
export interface MediaRequestMetaInfo extends MediaRequest{
  bandwidth: number,
  normalizatiom: number,
  limit: number
}

export interface MediaQueue {
  loaded?: boolean,
  attempts?: number,
  limit?: number,
  error?: MediaError,
  paused?: boolean,
  processing?: boolean,
  completed?: boolean,
  canceled?: boolean,
  active?: MediaJson,
  complete?: Array<MediaObject>,
  queue?: Array<FileObject>,
  queuePosition?: number,
  queueCount?: number,
  fullCount?: number
}

export interface MediaQueueStatus {
  queueLoaded?: boolean
  active?: FileObject,
  processing?: boolean,
  completed?: boolean,
  complete?: Array<MediaObject>,
  canceled?: boolean,
  error?: MediaError,
  position?: number,
  total?: number
}

export interface MediaJson {
  format: string,
  file: string | Blob | File,
  poster?: string | Blob | File
  name: string,
  user: string
}

export interface MediaPreSignOptions {
  form: 'group'|'profile'
  type: 'img'|'cover'
  user: string
}

export interface MediaJsonResponse {
  res: string,
  status: string,
  media_id: string,
  index: number
}

@Injectable({
  providedIn: 'root'
})

export class MediaService {

  private $unsubscribe:Subject<boolean> = new Subject();
  private $mediaQueue: BehaviorSubject<MediaQueue> = new BehaviorSubject({});
  private $queueStatus: BehaviorSubject<MediaQueueStatus> = new BehaviorSubject({});
  private files: Array<FileObject> = [];
  private media: Array<MediaObject> = [];

  public $queue: Observable<MediaQueue> = this.$mediaQueue.asObservable();
  public $status: Observable<MediaQueueStatus> = this.$queueStatus.asObservable();

  private queue : MediaQueue = {
    attempts: 0,
    limit: 1,
    processing: false,
    completed: true,
    error: null,
    paused: false,
    canceled: false,
    active: null,
    queue: [],
    complete: [],
    queuePosition: 0,
    queueCount: 0,
    fullCount: 0
  };
  
  private status: MediaQueueStatus = {
    queueLoaded: null,
    active: null,
    processing: false,
    completed: false,
    canceled: false,
    complete: [],
    error: null,
    position: 0,
    total: 0
  };

  private user:string;

  constructor(private model: FileModelService, private http: MediaHTTPClient, private ui: UiService) {
    
    //get media assets listed in FileModel packager
    model.$packager.subscribe(res => {
      //console.log('model packager ',res);
      
      //set media array to obj and queue
      this.media = res.map(obj => obj.media);
      this.queue.queue = res.map(obj => obj.file);

      //find packager array length
      if(res.length > 0) {
        //count items in the queue
        this.queue.fullCount = this.status.total = this.queue.queue.length;
        //console.log('queue count ',this.queue.fullCount);
        
        this.queue.queueCount = this.queue.queue.length;
        this.queue.loaded = this.status.queueLoaded = true; 
      } else {
        this.queue.loaded = this.status.queueLoaded = false;
      }

      //console.log('queue loaded? ',this.queue.loaded);
      
      this.$mediaQueue.next(this.queue);
      this.$queueStatus.next(this.status);
      //console.log(this.files);
    });
  }

  private sendRequest = async (user_id, options?):Promise<any> => {

    //console.log('queue ', this.queue);

    if(this.queue.paused){
      return;
    }

    this.queue.processing = this.status.processing = true;
    

    let ifLast = () => {
      if (this.queue.complete.length == this.queue.fullCount ) {
        this.queue.processing = this.status.processing = false;
        this.queue.completed = this.status.completed = true;
        this.queue.loaded = this.status.queueLoaded = false;
        this.$mediaQueue.next(this.queue);
        this.$queueStatus.next(this.status);
        // this.$unsubscribe.next(true);
        this.model.removeMedia();

        //console.log('on last of queue ', this.queue);
        //console.log('on last of queue  ', this.status);
      }
    }

    for (const file of this.queue.queue) {
      //console.log('current file in queue ', file);

      if(this.queue.error && this.queue.error.id == file.id){
        this.queue.error.retry = this.status.error.retry = !this.queue.error.retry
      } else {
        this.queue.queuePosition++;
        this.status.position = this.queue.queuePosition;
      }

      try {

        //search media array for specified file id and remove it from the array
        let media: MediaObject = this.media.filter((obj, i, arr) => {
          // console.log('File ID:', file.id);
          if (obj.id == file.id) {
            //console.log('Media ID:', obj.id);
            return true;
          }
        }).pop();
        
        let json: MediaJson = {
          format: file.type,
          file: file._file,
          name: file.name,
          user: user_id
        }

        //update active file object and pass next status result
        let updateStatus = ()=>{
          this.status.active = file;
          this.$queueStatus.next(this.status);
        }

        this.queue.active = json;
        this.$mediaQueue.next(this.queue);
        updateStatus();

        //console.log('mime type ', file.mime);
        if(file.mime.includes('image')){
          let res: string;
          file.initialized = file.uploading = true;
          updateStatus();
          res = await this.http.postJsonMedia(json, file.mime, options).pipe(take(1)).toPromise();
          media.url = res.replace( /.*(.com)/ , 'https://cloud.fanmire.com');
          file.completed = true;
          updateStatus();
          //console.log('Response:', res);
        }

        if (file.mime.includes('video')) {

          let videoUrl;
          
          //set default poster image
          let posterUrl = "https://cloud.fanmire.com/assets/backgroundimagefm.png";

          //console.log('media process file poster', file.poster );
          let poster: MediaJson = {
            format: 'png',
            file: file.poster,
            name: file.name,
            user: this.user
          }

          file.initialized = true;

          //check if file poster is defined
          if (file.poster !== undefined) {
            //upload poster image blob
            posterUrl = await this.http.postJsonMedia(poster, 'image/png').pipe(take(1)).toPromise();
            //console.log("med_ser vid poster " + posterUrl);
          } 
          
          //set poster url for the media object
          media.poster_image = posterUrl;

          file.uploading = true;
          updateStatus();

          //upload video file
          videoUrl = await this.http.postJsonMedia(json, file.mime).pipe(take(1)).toPromise();
          media.url = videoUrl;
          //console.log("med_ser videourl " + videoUrl);

          
          file.completed = true;
          updateStatus();
          //console.log('Response:', res);
        }        
        

        if(this.queue.error && this.queue.error.id == file.id){
          this.queue.error = null;
          this.status.error = null;
          this.queue.attempts = 0;
        }

        this.queue.queue = this.queue.queue.filter(_file => _file.id !== file.id);
        this.queue.queueCount = this.queue.queue.length;
        this.queue.active = null;
        this.status.active = null;
        this.status.complete.push(media);
        this.queue.complete.push(media);
        this.$mediaQueue.next(this.queue);
        this.$queueStatus.next(this.status); 
        // console.log('Post:', this.queue);
        ifLast();

      } catch (res) {
        
        let error: MediaError = {
          id: file.id,
          date: new Date,
          canceled: false,
          response: res,
          resolved: false,
          retry: false
        }

        // console.log('media status:', this.status);
        // console.log('media queue:', this.queue);


        if(this.queue.attempts == this.queue.limit){
          error.canceled = true;
        }

        // if(this.queue.error && this.queue.error.id == file.id){
        this.queue.error = this.status.error = error;
        // }

        this.$mediaQueue.next(this.queue);
        this.$queueStatus.next(this.status);

        // console.log('media status:', this.status);
        // console.log('media queue:', this.queue);

        break;

      }      

    }
    
  }

  //not referenced in this file
  pauseMedia():void {
    this.queue.paused = true;
    this.$mediaQueue.next(this.queue);
  }

  //not referenced in this file
  resumeMedia():void {
    this.queue.paused = false;
    this.$mediaQueue.next(this.queue);
  }

  //not referenced in this file
  retryMedia(): void {
    if(this.queue.attempts !== this.queue.limit){
      this.queue.attempts++;
      this.$mediaQueue.next(this.queue);
      this.sendRequest(this.user);
    } else {
      
      this.cancelMedia();
    }
  }

  cancelMedia(): void {
    this.queue.error.canceled = true;
    this.queue.error.retry = false;
    this.queue.canceled = true;
    this.queue.processing = false;
    this.queue.paused = false;
    this.status.error = this.queue.error;
    this.$mediaQueue.next(this.queue);
    this.$queueStatus.next(this.status);
    // this.ui.updateNotifications({text: 'Welcome to the entourage', type: 'info', dismiss: 'timer'});
  }

  //not referenced in this file
  clearMediaQueue():void {
    this.queue = {
      attempts: 0,
      limit: 2, //Number of max attempts minus 1
      processing: false,
      completed: true,
      error: null,
      paused: false,
      canceled: false,
      active: null,
      queue: [],
      complete: [],
      queuePosition: 0,
      queueCount: 0,
      fullCount: 0
    }
    
    this.status = {
      queueLoaded: null,
      active: null,
      processing: false,
      completed: false,
      canceled: false,
      complete: [],
      error: null,
      position: 0,
      total: 0
    };

    this.$queueStatus.next(this.status);
    this.$mediaQueue.next(this.queue);
    this.model.removeMedia();
  }


  publishMedia(user_id: string, options?:MediaPreSignOptions): Observable<MediaQueueStatus> {
    let model:ModelStatus = this.model.getStatus();

    this.user = user_id;

    if (model.processing){
      // let options = {
      //   text: 'Can\'t post at the momment.', sub_text: 'Files are still processing.',
      //   type: 'warn', //   dismiss: 'user'
      // } // this.ui.updateNotifications(options);
      
      return;
    }

    this.sendRequest(user_id, options);

    return this.$status;

  }

}