import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AsyncSubject, BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProfileService } from '../core/services/user.service';
import { EventObject, EventObjectBasic } from 'src/app/core/models/Events';
import { MediaObject } from './file-model.service'
import { ContentHTTPClient, HTTPError, HTTPResolvableError } from './http.service';
import { MediaService, MediaError, MediaQueueStatus } from './media.service'
import { UiService } from './ui.service';

export interface ContentObject {
  content_id?: string,
  platform?: string, //Web or Mobile
  content_status?: string, //Published, Draft, or Scheduled
  promoted?: string, //Bool as string
  media_type?: number,
  user_id?: string,
  user_image?: string,
  user_name?: string,
  user_type?: string,
  has_text?: boolean,
  content_text?: string,
  created_date?: string,
  content_allow?: string,
  content_media?: Array<MediaObject>,
  content_image?: string,
  content_video?: string,
  group?: ContentGroup,
  event_info?: EventObjectBasic,
  event_id?: string,
  content_category?: string,
  content_type?: string,
  tags?: Array<string>,
  link_url?: Array<string>,
  content_title?: string,
  exerpt?:string,
  notify?:string, 
  content_price?: string,
  subscriber_price?: string
}

export interface CompletedStatus {
  media?: boolean,
  content?: boolean,
  link?: Array<any>
}

export interface ContentAccess {
  accounts?: any
  can_blog?: boolean
  can_notify?: boolean
  groups?: Array<ContentGroup>
  active?: ContentGroup
}

export interface ContentGroup {
  group_id: string,
  group_name: string,
  interest: string,
  access: string,
  group_image: string,
  influencer_id?: string,
  member_id?: string
}

export interface publishObject {
  media?: MediaQueueStatus,
  content?: ContentObject,
  event?: EventObjectBasic
}

export interface publishStatus extends publishObject {
  complete?: CompletedStatus
  completed?: boolean
  error?: ContentError
  processing?: boolean
  paused?: boolean
  attempts?: number
  limit?: number
  canceled?: boolean
  progress?: number
  finalizing?: boolean
}

export interface ContentError {
  type: "media" | "content",
  error?: HTTPResolvableError | MediaError
}

@Injectable({
  providedIn: 'root'
})

export class CreateService implements OnInit {

  private createAccess: any = {};
  private user: any;
  private status: publishStatus = {
    media: null,
    content: null,
    processing: false,
    progress: 0,
    paused: false,
    attempts: 0,
    limit: 2,
    canceled: false,
    completed:false,
    complete: {
      media: false,
      content: false
    }
  };
  
  private $unsubscribe:Subject<boolean> = new Subject();
  private $publishStatus: BehaviorSubject<publishStatus> = new BehaviorSubject({});
  private $createAccess: BehaviorSubject<ContentAccess> = new BehaviorSubject({});
  private $validateContent: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public $status: Observable<publishStatus> = this.$publishStatus.asObservable();
  public $access: Observable<ContentAccess> = this.$createAccess.asObservable();
  public $validator: Observable<boolean> = this.$validateContent.asObservable();
  
  constructor(
    private http: ContentHTTPClient,
    private media: MediaService,
    private profile: ProfileService,
  ) {
    this.profile.getUser().subscribe(res => {
      // console.log(res);
      if(res){
        this.user = res;
        this.status.content = {
          platform: 'web',
          content_status: 'published',
          promoted: 'false',
          user_id: this.user.id,
          user_image: this.user.user_image,
          user_name: this.user.user_name,
          user_type: this.user.user_type,
          has_text: null,
          content_text: "",
          created_date: null,
          event_info: null,
          tags: [],
          link_url: [],
          content_video: "",
          content_image: "",
          content_title: "",
          exerpt: "",
          notify: "false"
        };
        
        this.getAccess();
        this.$publishStatus.next(this.status);
      }
    });

    this.media.$status.subscribe(res => {
      if(res){
        this.status.media = res;
        // this.validateContent();
      }
      // this.status.media = res;
      // this.validateContent();
    });
   }

  ngOnInit(): void {
    this.$publishStatus.next(this.status);
  }

  private publish(): void {
    //console.log('begin publish fx');
    this.status.content.created_date = new Date().toISOString();
    this.status.progress = 10;
    //console.log(this.status);

     //update status
    this.$publishStatus.next(this.status);

    //console.log('queue loaded on create? ',this.status.media.queueLoaded);

    //if a media queue is available upload media
    if(this.status.media.queueLoaded) {
      //console.log('total status media', this.status.media.total);
      let step = 85/this.status.media.total;
      let steps = 0;

      //console.log('begin publish media');
      this.media.publishMedia(this.user.id).pipe(takeUntil(this.$unsubscribe)).subscribe((res) => {
        //console.log('pub media begin', res);
        //console.log('step', step);
        this.status.media = res;
        this.status.content.content_media = [];

        if(this.status.media.processing && steps < res.complete.length ){
          steps++;
          this.status.media.complete.map(media => {
            // console.log(media);
            if(media.media_type.includes('image')){
              // let re = new RegExp('(?<!(<video|<source)(?:.*?))(?<=(?:img.*)(?:id="' + media.id + '"(?:.*?)src="))(?:(blob:.*?))(?:=")','gm');
              let re = new RegExp('((?:^.*\s*)*)(<img(?:.*)(?:id="))(' + media.id + ')("(?:.*?)src=")((?:blob:)?.*?)(".*?\/?>)(.*\s*)*', 'gm');
              // console.log(re);
              this.status.content.content_text = this.status.content.content_text.replace(re, '$1$2$3$4'+media.url+'$6$7');
              return media;
            } else if(media.media_type.includes('video')) {
              // let srcRe = new RegExp('(?<=video.*?)((?<=(?:id="' + media.id + '")(?:(?:.*?)(?:source src=")))(blob:.*?)(?="))','gm');
              let srcRe = new RegExp('((?:^.*\s*)*)(<video(?:.*)(?:id="))(' + media.id + ')("(?:.*?)>)(.*?\s*?)(<source.*?src=")(.*?)(".*?>)(.*\s*)*','gm');

              // let posterRe = new RegExp('(?<=video.*?)((?<=(?:id="' + media.id + '")(?:(?:.*?)(?:poster=")))(blob:.*?)(?="))', 'gm');
              let posterRe = new RegExp('((?:^.*\s*)*)(<video(?:.*)(?:id="))(' + media.id + ')("(?:.*?)poster=")((?:blob:)?.*?)(".*\/?>)(.*\s*)*', 'gm');

              //console.log('poster regex ', posterRe)

              this.status.content.content_text = this.status.content.content_text.replace(srcRe, '$1$2$3$4$5$6' + media.url +'$8$9');
              this.status.content.content_text = this.status.content.content_text.replace(posterRe, '$1$2$3$4'+media.poster_image+'$6$7');
            }
          })
          
          this.status.progress = this.status.progress + step;
          this.$publishStatus.next(this.status);
        }

  
        if(res.completed && !this.status.finalizing) {
          //console.log('completing media moving to post content');
          this.status.content.content_media = res.complete;
          this.status.complete.media = true;
          this.status.finalizing = true;
  
          this.http.postContent(this.status.content).subscribe((res) => {
            // console.log('post content res', res);
            if(res){

              if(this.status.error && this.status.error.type == 'content' && !this.status.error.error.resolved){
                this.status.error.error.resolved = true;
              }

              this.status.content.content_id = res;
              this.status.complete.content = true;
              this.status.complete.link = ["/post", res];
              this.status.processing = false;
              this.status.completed = true;
              this.$publishStatus.next(this.status);
              this.$unsubscribe.next(true);
              this.media.clearMediaQueue();

              // this.router.navigateByUrl('/post/' + res);

            }
            
          }, (error) => {
            let err: ContentError = {
              type: 'content',
              error: {
                resolved: false,
                response: error,
                retry: false
              }
            }
            this.status.error = err;
            this.status.paused = true;
            this.$publishStatus.next(this.status);
            this.$unsubscribe.next(true);
          });
        }
  
        if(res.error) {

          // console.log('media error', res.error);

          let error: ContentError = {
            type: 'media',
            error: res.error
          }

          if(error.error.canceled){
            this.status.canceled = true;
          }

          this.status.error = error;
          this.status.paused = true;
          this.$publishStatus.next(this.status);
          // this.$unsubscribe.next(true);
        }

        //this.$publishStatus.next(this.status);
  
      });
    } else {
      //no media just upload post content
      this.postContent();
    }

  }

  public validateContent(): void {

    //console.log('begin validation');
      let validators = {
        valid: false,
        hasTitle: false,
        hasExcerpt: false,
        hasText: false,
        hasMedia: false
      } 

      //check content fr text values
      if(!this.status.content.content_text || this.status.content.content_text !== "") {
        let dom = new DOMParser().parseFromString(this.status.content.content_text, 'text/html');
        // console.log(dom.body.innerText);
        // console.log(dom.body.innerText.length)
        validators.hasText = this.status.content.has_text = (dom.body.innerText.length > 0)?true: false;
        // console.log()
        this.$validateContent.next(validators.hasText);
      }

      //check content for a media queue
      if(this.status.media.queueLoaded) {
        validators.hasMedia = true;
        this.$validateContent.next(validators.hasMedia);
        this.status.content.content_type = "1";
      }
       //console.log('status ' , this.status);
       //console.log('validators ' , validators);

      if(validators.hasText || validators.hasMedia){
        this.$validateContent.next(false);
      } else {
        this.$validateContent.next(true);
      }
      
  }

  public publishContent():Observable<publishStatus> {
    //console.log('publishing status ', this.status);
    this.status.processing = true;
    this.publish();

    //console.log('publishing status before return ', this.status);
    return this.$status;
  }

  private postContent():void {
    this.http.postContent(this.status.content).subscribe((res) => {
      // console.log('post content res', res);

      if (res == null) {
        this.status.error = { type:'content', error: { error_id: "0", response: "failed"  } };
        this.status.processing = false;
        this.status.completed = true;
      } else {

        if(this.status.error && this.status.error.type == 'content'){
          this.status.error.error.resolved = true;
        }

        this.status.content.content_id = res;
        this.status.complete.content = true;
        this.status.complete.link = ["/post", res];
        this.status.completed = true;

      }

      this.$publishStatus.next(this.status);
    }, (error) => {
      let err: ContentError = {
        type: 'content',
        error: {
          resolved: false,
          response: error,
          retry: false
        }
      }

      this.status.error = err;
      this.status.paused = true;
      this.$publishStatus.next(this.status);
      this.$unsubscribe.next(true);
    });
  }

  public retry(error:ContentError):void {

    if(error.type == 'content'){
      if(this.status.attempts !== this.status.limit){
        this.status.attempts++;
        this.status.paused = false;
        this.$publishStatus.next(this.status);
        this.postContent();
      } else {
        this.status.error.error.canceled = true;
        this.status.canceled = true;
        this.$publishStatus.next(this.status);
      }
      // this.status.error = null;
      
    } else if (error.type == 'media'){
      // this.status.error.error.retry = true;
      this.media.retryMedia();
    }
  }

  public setAccess(group: ContentGroup): void {
    this.status.content.content_category = group.interest;
    this.status.content.content_allow = group.access;
    this.status.content.group = group;
    this.createAccess.active = group;
    // console.log(this.createAccess);
    this.$createAccess.next(this.createAccess);
    this.$publishStatus.next(this.status);
  }

  public setEvent(group: string, event: EventObjectBasic): void {
    // console.log(group)
    let access = this.getAccessById(group);
    // console.log(access)
    this.status.content.event_info = event;
    this.status.content.event_id = event.event_id;
    this.status.event = event;
    this.setAccess(access);
  }

  public getAccess(): void {
    this.http.getCreateAccess(this.user.id).subscribe(res => {
      if(res){
        // console.log('Access', res);
        this.createAccess = res;
        this.$createAccess.next(this.createAccess);
      }
    });
  }

  public getAccessById(id): ContentGroup {
    let access = this.createAccess.groups.filter(res => res.group_id == id);
    return access.pop();
  }

  public getCurrentAccess(): ContentGroup {
    return this.createAccess.active;
  }

  public setContent(content: ContentObject): void {

    // console.log('set content ', content);
    // console.log(content);
    this.status.content = content;
    let dom = new DOMParser().parseFromString(this.status.content.content_text, 'text/html');
    
    this.status.content.has_text = (dom.body.innerText.length > 0)?true: false;
    this.validateContent();
    this.$publishStatus.next(this.status);
  }

  checkStatus(){
    return this.status.processing;
  }

  reset() :void {
    // console.log('Create Reset')
    this.createAccess.active = null;
    if(this.user){
      this.status = {
        media: null,
        content: {
          platform: 'web',
          content_status: 'published',
          promoted: 'false',
          user_id: this.user.id,
          user_image: this.user.user_image,
          user_name: this.user.user_name,
          user_type: this.user.user_type,
          has_text: null,
          content_text: "",
          created_date: null,
          tags: [],
          link_url: [],
          content_video: "",
          content_image: "",
          content_title: "",
          exerpt: "",
          notify: "false",
          event_info: null,
          event_id: null
        },
        event: null,
        progress: 0,
        processing: false,
        finalizing: false,
        canceled: false,
        completed:false,
        complete: {
          media: false,
          content: false
        }
      }
    }
    this.media.clearMediaQueue();
    // June 20 23 pushing a new create access may make entourage select pop up
    //this.$createAccess.next(this.createAccess);
    this.$publishStatus.next(this.status);
  }

}
