import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentObject } from './create.service';
import { BaseService } from './base.service';
import { catchError, delay } from 'rxjs/operators';
import { url } from 'inspector';
import { MediaJson, MediaPreSignOptions } from './media.service';
import { makeId } from '../core/util/make-id.util';

export interface HTTPError {
  error_id?: string,
  path?: string,
  response?: string,
  code?: string
}

export interface HTTPFatalError extends HTTPError {
  fatal: boolean
}

export interface HTTPResolvableError extends HTTPError {
  resolved?: boolean,
  retry?: boolean,
  canceled?: boolean
}

@Injectable({
  providedIn: 'root'
})

export class HTTPClient extends BaseService {  
  httpHead = new HttpHeaders();
  options = {};
  ENDPOINT: string;

  constructor(protected http:HttpClient) { 
    super();
    this.httpHead.set('Content-Type', 'application/json');
    this.options = {
      headers: this.httpHead,
      reportProgress: false,
    };

    this.ENDPOINT = this.server;
  }
  
  $post(path:string, body:any, useOptions: boolean = true):Observable<any> {

    if (useOptions == false){
      return this.http.post(path, body);
    }

    return this.http.post(path, body, this.options)
      // .pipe( 
      //   catchError(this.handleError)
      // );
  }

  $get(path:string):Observable<any> {
    return this.http.get(path, this.options);
  }

  $put(path: string, data: any, headers?: HttpHeaders): any {
    return this.http.put(path, data, {headers:headers});
  }

  $delete(path: string):Observable<any> {
    return this.http.delete(path, this.options);
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      //console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      // console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return new Observable<string>(observer => observer.next('error') );
  }
}

@Injectable({
  providedIn: 'root'
})

export class MediaHTTPClient extends HTTPClient {

  constructor(protected http:HttpClient) {
    super(http);
   }

  private preSign(mediaData: MediaJson, mime: string, presignOptions: MediaPreSignOptions): any {
    let url: string = 'media/uplink';
    let asset = (presignOptions && presignOptions.form == 'profile')?presignOptions.user: mediaData.name + '.' + mediaData.format;
    let data: any = {
      asset_name: asset,
      content_type: mime,
      user_id: mediaData.user
    }

    //console.log(mediaData);
    //console.log(data);

    if(presignOptions){
      //console.log(presignOptions);
      url = url + '/' + presignOptions.form + '/' + presignOptions.type;
    }

    //console.log(url)

    //console.log(mediaData);

    return new Observable<any>(obs => {
      this.$post(this.server + url, data).subscribe(res => {
        if(res){
          obs.next(res);
          obs.complete();
        }
      });
    });
  }

  postJsonMedia(postJson: any, mime?: string, presignOptions?:MediaPreSignOptions): any|Observable<any> {

      return new Observable<any>(obs => {
        this.preSign(postJson, mime, presignOptions).subscribe(url => {
          // console.log(url);
          let headers = new HttpHeaders();
          headers = headers.set('x-amz-acl','public-read');
          headers = headers.set('Content-Type', mime);
          this.$put(url, postJson.file, headers).pipe(delay(500)).subscribe(res => {
            // console.log(res);
            
            let out = url.replace( /.*(.com)/ , 'https://cloud.fanmire.com').split('?')[0];

            // if(presignOptions.form == 'profile'){
            //   out = out.replace(/(\/{1}[^/]*$)/,'/')
            // }


            obs.next(out);
            obs.complete();
          }, error => {
            // obs.next(error);
            obs.error(error);
            obs.complete();
          });
        }, error => {
          obs.error(error);
          obs.complete();
        });
      });
  }

  //post media using multipart form sent to server
  postMedia(files: Array<any>) : Observable<any> {
    if (files === undefined) {
      return new Observable((obs) => {
        obs.next(undefined);
        obs.complete();
      });
    } 

    const data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files[i].name + '.' + files[i].type, files[i]._file, files[i].name);
    }
    return this.$post(this.ENDPOINT + 'media', data, false);
  }

}

@Injectable({
  providedIn: 'root'
})

export class ContentHTTPClient extends HTTPClient {

  constructor(protected http:HttpClient) {
    super(http);
  }

  //  Create Post
  postContent(postJson: ContentObject): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.$post(this.ENDPOINT + this.CONTENT_UPLOAD, postJson);
  }

  updateContent(updateJson: any): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.$post(this.ENDPOINT + this.CONTENT_UPLOAD, updateJson);
  }

  // Get post content by ID
  getPostContent(userId: string, postId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.$get(this.ENDPOINT + 'feed/single/' + postId + '/' + userId)
  }

  // Get post content by ID
  getPostFeedContent(userId: string, postId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.$get(this.ENDPOINT + this.CONTENT_UPLOAD + postId + '/' + userId)
  }

  getSavedPostContent(userId: string, type: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.$get(this.ENDPOINT + 'content/view/' + type + '/' + userId)
  }

  // Delete Post Content 
  deletePostContent(contentId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.$delete(this.ENDPOINT + 'content/' + contentId);
  }

  getCreateAccess(userid: string) : Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'create/' + userid, options);
  }

}

@Injectable({
  providedIn: 'root'
})

export class CommentsHTTPClient extends HTTPClient {

  constructor(protected http:HttpClient) {
    super(http);
   }

   // Comments

  getCommentsForPost(userid: string, postId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.$get(this.ENDPOINT + 'comments/' + userid + '/' + postId);
  }

  getCommentsPage(userid: string, postId: string, segment: number): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.$get(this.ENDPOINT + 'comments/' + userid + '/' +postId + '/page/' + segment);
  }

  postComment(commentJson: any): Observable<any> {
    /*
    var newComment = {  
        content_id: postid,
        user_id: userid,  
        user_name: user_name, 
        user_image: user_image,  
        comment_text: text,
        comment_date: now, 
        comment_type: "text",
        media_type: "",
        comment_image: "",
        comment_status: "visible",
        comment_id: comId,
        replies: [],
        likes: []
    };
    */
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.$post(this.ENDPOINT + 'comments', commentJson);
  }

  //post comment like
  postCommentLike(userId: string, commentId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false
    };

    return this.$post(this.ENDPOINT +  'like/comment/' + commentId + '/' + userId, undefined);

  } 

  //delete comment
  deleteComment(commentId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.$delete(this.ENDPOINT + 'comments/' + commentId);
  }

  //get comment replies
  getCommentReplies(userid: string, commentId: string, segment: number): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return this.$get(this.ENDPOINT + 'comments/replys/' + userid + '/' +  commentId + '/page/' + segment);
  }

  //post comment reply
  postReply(commentId: string, replyJson: any, isEdit: boolean): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    let link = this.ENDPOINT + 'comments/reply/' + commentId;
    if (isEdit) {
      link = this.ENDPOINT + 'comments/reply/' + commentId + '/update';
    }

    return this.$post(link, replyJson);
  }

  postReplyLike(replyId: string, likeJson: any): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    let link = this.ENDPOINT + 'like/reply/' + replyId + '/vote/up';

    return new Observable((observer) => {
      this.$post(link, likeJson);
    });
  }

  deleteReply(commentId: string){
    return new Observable((observer) => {
      this.$delete(this.ENDPOINT + 'comments/reply/' + commentId).subscribe((res)=>{
        observer.next(res);
      }, (error)=> {
        observer.error(error);
      });
    });
  }

  

}





