import { Injectable, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { AsyncSubject, BehaviorSubject, combineLatest, Observable, observable, of } from "rxjs";
import { map, mergeMap, take, takeUntil } from "rxjs/operators";
import { UserBasic, FollowingObject, UserAboutObject, ProfileObject, UserDetails, WallObject, GroupBasicObject, GroupObject, InterestBasicObject, InterestObject,} from "src/app/core/models/UserModels";
import { ProfileHttpService, ProfileStatus} from "../http/user-http.service";
import { Interest } from "../models/Post";


@Injectable({
    providedIn: 'root'
})

export class ProfileService implements OnInit {

    public profile: ProfileStatus;
    private _init: boolean = false;
    private init: AsyncSubject<boolean> = new AsyncSubject<boolean>();
    false: boolean;

    constructor(private profileHttp: ProfileHttpService, cookies: CookieService){
        // super(ProfileService);
        
        profileHttp.$profileStatus.subscribe(res => {
            if(res){
                this.profile = res;
                if(!this._init){
                    this._init = true;
                    this.init.next(true);
                    this.init.complete();
                }
            }
        });
    }

    ngOnInit(): void {
        
    }

    getUser(refresh?: boolean): Observable<ProfileObject> {

        // if(refresh){
        //     this.false = true;
        //     this.init = new AsyncSubject();
        //     this.profileHttp.refreshUserDetails();
        // }
        return new Observable<ProfileObject>(obs => {
            this.init.asObservable().pipe(
                mergeMap(res => this.profile.complete.asObservable()),
                mergeMap(res => this.profile.profile.asObservable())
            ).subscribe(res => {
                if(res) {
                    // console.log(res)
                    obs.next(res);
                    obs.complete();
                }
                
            })
        })
        
    }

    getUserDetails(): Observable<UserDetails> {
        return new Observable<UserDetails>(obs => {
            this.init.asObservable().pipe(
                mergeMap(res => this.profile.complete.asObservable()),
                mergeMap(res => this.profile.user)
            ).subscribe(res => {
                obs.next(res);
                obs.complete();
            })
        })
    }

    getUserBasic(): Observable<UserBasic> {
        return new Observable<UserBasic>(obs => {
            this.init.asObservable().pipe(
                mergeMap(res => this.profile.complete.asObservable()),
                mergeMap(res => this.profile.user)
            ).subscribe(res => {
                if(res) {
                    let data = {
                        user_id: res.id,
                        user_name: res.name,
                        user_image: res.image,
                        user_covor_image: res.cover
                    }
                    obs.next(data);
                    obs.complete();
                }
            })
        })
    }

    getUserById(user_id: string): any {
        return new Observable<UserDetails>(obs => {
            this.profileHttp.getUserDetails(user_id).subscribe((res:UserDetails) => {
                obs.next(res);
                obs.complete();
            })
        })
    }

    updateUserMedia(file: any, type: 'profile'| 'cover'): any {
        this.profileHttp.postProfileMedia(file, type);
    }

    /**@param target
     * (Optional) target is the :id param within route path
     * 
     * returns a combined observable containing the profile object and wall object
     */
    getWall(target?: string):Observable<[ProfileObject, WallObject]> {
        // console.log(target)
        let wall = new Observable<any>(obs => {
            this.profileHttp.getUserWall(target).pipe(map(res => res)).subscribe((res:WallObject|null)=>{
                if(!res) {
                    obs.next(null);
                    obs.complete();
                }
                obs.next(res);
                obs.complete();
            })
        });
        const combined = combineLatest([(!this.profile || !this.profile.profile)?this.getUser():this.profile.profile.asObservable(),wall]).pipe(take(1));
        return combined;
    }

    getBookmarks(profileId: string, isCollection?: boolean){
        return this.profileHttp.getBookmarkFeed(profileId, isCollection)
    }

    getUserInterest(){
        return new Observable<InterestBasicObject[]|InterestObject[]>(obs => {
            this.init.asObservable().pipe(
                mergeMap(res => this.profile.complete.asObservable()),
                mergeMap(res => this.profile.user.asObservable())
            ).subscribe(res => {
                if(res) {
                    
                    obs.next(res.interests);
                    obs.complete();
                }
            })
        })
    }

    getAllInterest(){
        return this.profileHttp.getInterestList();
    }

    updateUserInterest(interest) {
        return this.profileHttp.postUserInterest(interest)
    }

    getBookmarkCollection(userId:string){
        return this.profileHttp.getBookmarkCollection(userId);
    }


}