import { Injectable } from '@angular/core';
import { Router, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CreateService } from './services/create.service';
import { UiService } from './services/ui.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformAuthGuard  {

  constructor(private cookie: CookieService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user: string = this.cookie.get('user_id');
    if (user === undefined || user === '') {
        return true;
    }
    //return this.router.createUrlTree(['']);
    this.router.navigateByUrl('explore');
    return false;
    
  }
}

@Injectable({
  providedIn: 'root'
})
export class NewContentGuard  {

  private status = false;

  constructor(private ui: UiService, public create: CreateService, private router: Router) {
    
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean>((obs) => {
      this.create.$status.pipe(take(1)).subscribe(res => {
        // console.log(res);
        if(res.processing){
          this.ui.updateNotifications({
              text:'Sorry, your content is still processing',             
              sub_text: 'Please wait for the current post to finish processing before creating a new post.', 
              type: 'warn', 
              dismiss: 'timer'
          });
          obs.next(false);
        } else {
          // this.create.reset();
          obs.next(true);
        }
      })
    });
    
  }
}

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {

  constructor(private create: CreateService){}

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    if (component.canDeactivate()){
      return true
    }

    if (confirm('WARNING: You have unsaved changes. Press Cancel to continue editing or Press OK and lose your changes.')){
      this.create.reset();
      return true
    }

    return false
      
  }
}