import { Directive, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as FileType from '../../../node_modules/file-type/browser';
import { NotificationObject, UiService } from './ui.service';

export interface FileBasic {
  id: string,
  mime?: string,
  _file?: string | Blob | File
  poster?: string | Blob | File  
}

export interface FileObject extends FileBasic {
  name: string,
  type: string,
  size: number,
  // data_url: string | ArrayBuffer,
  object_url: string,
  poster_url?: string,
  completed?: boolean
  initialized?: boolean
  uploading?: boolean
  
}

export interface MediaObject {
  id?: string,
  url?: string,
  media_type?: string,
  dimension?: number,
  extension?: string,
  size?: number,
  row?: number,
  column?: number,
  height?: number,
  width?: number,
  orientation?: string,
  poster_image?: string
}

export interface ModelStatus {
  processing: boolean,
  completed: Array<FileBasic>,
  queue: Array<File>,
  fullCount: number
}

export interface ImageDimensions {
  height: number,
  width: number,
  dimension: number
}

//To be depricated
export interface MediaPackage {
  id: string,
  media: MediaObject,
  file: FileObject,
}


@Injectable({
  providedIn: 'root'
})
 
export class FileModelService {

  constructor(public ui: UiService) { }

  protected MEDIA_LIMIT:number = 5;

  // File status Init
  private model: ModelStatus = {
    processing: false,
    completed: [],
    queue: [],
    fullCount: 0
  }

  private fileData: Array<FileObject> = [];
  private mediaData: Array<MediaObject> = [];
  private packageData: Array<MediaPackage> = [];
  private $fileData: BehaviorSubject<Array<FileObject>> = new BehaviorSubject([]);
  private $mediaData: BehaviorSubject<Array<MediaObject>> = new BehaviorSubject([]);
  private $mediaPackager: BehaviorSubject<Array<MediaPackage>> = new BehaviorSubject([]);
  private $model: BehaviorSubject<ModelStatus> = new BehaviorSubject(this.model);

  public $files: Observable<Array<FileObject>> = this.$fileData.asObservable();
  public $media: Observable<Array<MediaObject>> = this.$mediaData.asObservable();
  public $packager: Observable<Array<MediaPackage>> = this.$mediaPackager.asObservable();
  public $modelStatus: Observable<ModelStatus> = this.$model.asObservable();


  // Public facing method for adding files to the processing queue
  // Accepts FileList of Array of Files
  // If passing a blob use '{Blob} as File' wrapped in an array and pass true as second arg


  private limit(_files:Array<File>):Array<File> {

    //find file limit and notify
    let index:number = this.MEDIA_LIMIT - this.model.fullCount;
    //console.log('file model index', index);

    //console.log('files length', _files.length);

    //warning if max limit exceeded
    if(_files.length > this.MEDIA_LIMIT || _files.length > index){
      let options: NotificationObject = {
        text: 'Maximum number of files allowed has been excceded',
        sub_text: 'You can only upload ' + this.MEDIA_LIMIT + ' files per post',
        type: 'warn',
        dismiss: 'user'
      }
      this.ui.updateNotifications(options);
    }

    //console.log('before splice ', _files);
    _files.splice(index);
    //console.log('after splice ', _files);
    return _files;   
  }

  private init = (files):void => {

    //add file array to model queue
    this.model.queue = this.limit(Array.from(files));

    //if no file return
    if(this.model.queue.length == 0){
      //console.log("queue length 0, ending init");
      return;
    }
    
    //console.log('init files ', this.model.queue);
    //console.log('init completed ', this.model.completed)
    //console.log('init model ', this.model);
    //Process files
    this.processFiles();
  }

  public loadFiles(files: FileList | Array<File>): Observable<ModelStatus> {

    //Init process tracking
    //If currently processing queue, wait until completed
    if(this.model.processing){
      let $unsubscribe: Subject<boolean> = new Subject();
      this.$modelStatus.pipe(takeUntil($unsubscribe)).subscribe(res => {
        console.log('loadfiles takeuntil'); //firefox error
        if(res.completed) { 
          this.init(files);
          $unsubscribe.next(true);
        }
      });
    } else {
      console.log('loadfiles else');
      this.init(files);
    }

    return this.$modelStatus;
  }

  private processFiles(): void {
    //console.log("begin processing files");

    //Update queue tracking 
    this.model.processing = true;
    this.model.fullCount = this.model.fullCount > 0? this.model.queue.length + this.model.fullCount: this.model.queue.length;
    this.$model.next(this.model);

    //check for iOS safari browser
    let clientSafari = this.isIosSafari();

    //loop through files in the queue
    this.model.queue.forEach((file, index) => {

      //console.log('file',file);

      //file size 
      //300MB -> 367001600
      //1GB -> 1073741824
      //2GB -> 2147483648      
      if(file.size > 2247483648) //check file size memory limit | current 2.09GB
      {
        this.ui.updateNotifications({text: 'File is too large', sub_text:'Files are limited to 1GB', type: 'warn', dismiss: 'timer'});
        //console.log(this.model.queue.length);
        this.model.queue.splice(-index, 1); //Remove from queue
        //console.log(this.model.queue.length);
        this.model.fullCount--;
        this.model.processing = false;
        this.model.fullCount = this.model.fullCount > 0? this.model.queue.length + this.model.fullCount: this.model.queue.length;
        this.$model.next(this.model);
        return;
      } 

      const reader = new FileReader();
      let type: any;
      let blob: Blob;

      // Itterate queue position and calculate queue length
      // Then update status 
      let _file: FileBasic = {
        id: this.makeid()
      }  

      reader.onload = (evt) => {
        console.log('process render ', evt); console.log('process file ',file);
 
        let ext = null;
        _file._file = URL.createObjectURL(new Blob([file], { type: file.type }));
        _file.mime = (type.mime) ? type.mime : file.type;
        
        // Get file extention 
        if (file.name !== undefined) {
          ext = file.name.split('.').pop();
        }

        //console.log('size',file.size);  

        // //Clear for active file
        // if (!this.status.active) {
        //   this.status.active = [];
        // }

        // //Set active File for processing
        // if (this.status.active.length > 0) {
        //   this.status.active.pop();
        // }

        // Set active and update status
        //this.status.active.push(file);
        //this.$status.next(this.status);

        //If last object in the queue, stop processing once done
        let ifLast = () => {
          if (this.model.completed.length == this.model.fullCount ) {
            // console.log('full', this.status.fullCount);
            // console.log('pos', this.status.queuePosition);
            // console.log('com', this.status.complete.length);
            this.model.processing = false;
            // this.status.queuePosition = 0;
            // this.status.completed = true;
            this.$model.next(this.model);
            this.$fileData.next(this.fileData);
            this.$mediaData.next(this.mediaData);
            this.$mediaPackager.next(this.packageData);
          } else {
            this.$model.next(this.model);
          }
        }

        //console.log('set media object');

        //Object representation of Media
        //To be used as content_media object within content object
        let mediaObj: MediaObject = {
          id: _file.id,
          url: null,
          media_type: (type.mime) ? type.mime : file.type,
          dimension: null,
          extension: ext !== null ? ext : 'jpeg',
          size: file.size,
          row: 1,
          column: 1,
          height: null,
          width: null,
          orientation: ""
        }

        //console.log('set file object');

        //Object representation of File
        //To be used internally for displaying media back to user and uploading to media endpoint
        let fileObj: FileObject = {
          id: _file.id,
          name: "fmm_" + this.makeid(),
          type: ext !== null ? ext : 'mp4',
          mime: (type.mime) ? type.mime : file.type,
          size: file.size,
          // data_url: (evt.target.result as string).split(',').pop(),
          object_url: URL.createObjectURL(new Blob([file], { type: file.type })),
          _file: file
        }

        //console.log('file type', file.type);

        if (file.type.includes('image')) {
          //console.log('image found begin image process');

          //Process image
          this.processImage(evt).subscribe((res) => {

            // Update MediaObject with image dimensions
            mediaObj.dimension = res.dimension;
            mediaObj.height = res.height;
            mediaObj.width = res.width;
            mediaObj.orientation = res.dimension > 1 ? 'landscape' : 'portrait';

            if (res.dimension == 1){
              mediaObj.orientation = "square";
            }

            fileObj.completed = true;
            
            console.log("image media obj", mediaObj)
            
            // Create MediaPackage
            let payload: MediaPackage = { 
              id: _file.id, 
              media: mediaObj, 
              file: fileObj 
            };

            //console.log('index', index)
            // Update Arrays
            this.mediaData.push(mediaObj);
            this.fileData.push(fileObj);
            this.packageData.push(payload);
            this.model.queue.splice(-index, 1); //Remove from queue
            this.model.completed.push(_file); // Add to completed array

            //Testing
            this.$model.next(this.model);
            this.$fileData.next(this.fileData);
            this.$mediaData.next(this.mediaData);
            //this.status.active.pop();
            // Update status
            ifLast();
            
          });

        } else if(file.type.includes('video')) {
          // Process video 
          
          //console.log('video found begin video process');  
          //console.log('file model file poster', fileObj.poster );  

          //load video metadata for poster image  
          let video = document.createElement('video');
          video.src = fileObj.object_url; 
          video.preload="auto";

          //safari won't load data until video is played
          if (clientSafari == true) {  
            video.autoplay = true;
          }
          //console.log('begin video data load ');             

          fromEvent(video,'loadedmetadata').pipe(take(1)).subscribe(res => {
            ////console.log('load video metadata', res);

            //create canvas to capture poster image            
            let canvas = document.createElement('canvas');
            let height = video.videoHeight;
            let width = video.videoWidth;
            let ratio = width/height;
            let seekTo = Math.floor(video.duration/4);
            //console.log('seek position', seekTo);
            // console.log('native height',video.videoHeight);
            // console.log('native width', video.videoWidth);
            // console.log('ratio', ratio);
            /*
            if(ratio > 1 && height > 720 ){
              canvas.height = height = 720
              canvas.width = width = 720*ratio
              //console.log(canvas.width)
            } else if(ratio < 1 && width > 720){
              canvas.height = height = 720*ratio
              canvas.width = width = 720
            } else if (ratio == 1 && width > 720){
              canvas.height =  height = 720
              canvas.width =  width = 720
            } else {
              canvas.height =  height
              canvas.width =  width
            }
              */
            
            let maxWidth = 800; 
            canvas.height = height;
            canvas.width = width;

            if (canvas.width > maxWidth) {
              let scale = maxWidth / width;
              canvas.height = height*scale;
              canvas.width = maxWidth;
            }

            // Update MediaObject with image dimensions
            mediaObj.dimension = ratio;
            mediaObj.height = height;
            mediaObj.width = width;
            mediaObj.orientation = ratio > 1 ? 'landscape' : 'portrait';

            if (ratio == 1){
              mediaObj.orientation = "square";
            }

            console.log("media Object", mediaObj);
            console.log("canvas", canvas);
            // Create MediaPackage
            let payload: MediaPackage = { 
              id: _file.id, 
              media: mediaObj, 
              file: fileObj
            };
            
            // Update Arrays
            this.mediaData.push(mediaObj);
            this.fileData.push(fileObj);
            this.packageData.push(payload);  

            //console.log('canvas data', canvas);
            let context = canvas.getContext('2d');

            //console.log('begin loded data event');       

            //load video data to capture poster image
            fromEvent(video,'loadeddata').pipe(take(1)).subscribe(res => {
              //console.log('loaded video data and set seek');
              //seek position
              video.currentTime = seekTo;

              //after seek
              fromEvent(video,'seeked').pipe(take(1)).subscribe(res => {
                //console.log('seek complete draw poster on canvas');

                context.drawImage(video, 0,0, canvas.width, canvas.height);

                //set canvas as blob image
                canvas.toBlob(res => {
                  _file.poster = fileObj.poster_url = URL.createObjectURL(res);
                  fileObj.poster = res;

                  //console.log('poster blob ',res);

                  // Update File Data
                  this.fileData.map(res => {
                    if (res.id == _file.id) {
                      res.poster = fileObj.poster
                      //console.log(res)
                      return res;
                    }
                    return res
                  });
                  this.$fileData.next(this.fileData);

                  // Update Package
                  this.packageData.map(res => {
                    if (res.id == _file.id) {
                      res.file = fileObj
                      return res;
                    }
                    return res
                  });

                  //add data to media packager
                  //console.log('send next package data ', this.packageData);
                  this.$mediaPackager.next(this.packageData);

                  // Update Model Status
                  this.model.completed.map(res => {
                    if (res.id == _file.id) {
                      res = _file
                      return res;
                    }
                    return res
                  }) 
                })
              });                
            }) 
          });
          
          //add data to model
          //console.log('send next model data ', this.model); 
          
          this.model.queue.splice(-index, 1); //Remove from queue
          this.model.completed.push(_file); // Add to completed array
          //this.status.queue.splice(-index, 1); //Remove from queue
          //this.status.complete.push(media); //add to completed array
          //this.status.active.pop();
          // Update status

          //Testing
          this.$model.next(this.model);
          this.$fileData.next(this.fileData);
          this.$mediaData.next(this.mediaData);
          ifLast();
        }

      };


      // Polyfill for quicktime videos
      this.checkMimeType(file).subscribe((val) => {
        //console.log(val);
        if (val.mime.includes('quicktime')) {
          blob = new Blob([file], { type: 'video/mp4' });
          type = { ext: 'mp4', mime: 'video/mp4' };
          file = blob as File;
          reader.readAsDataURL(blob);
        } else {
          type = val;
          reader.readAsDataURL(file);
        }

      });

    });
  }


  // Get File processing status
  // Defer to subscribing to $fileStatus for continous updates
  getStatus(): any {
    return this.model;
  }

  // Get cashed media
  // Pass media id to get specified objects
  getMedia(id?: string): Array<FileBasic> | FileBasic {

    // If media id is present, return the single MediaPackage object
    if(id){
      let _package = this.model.completed.find(x => x.id === id);
      return _package;
    }

    // Else, return complete array of MediaPakages
    return this.model.completed;
      
  }

  // Clear cached media
  // Pass media id to remove specified objects
  removeMedia(id?:string): void {

    // If media id is present, remove by media id
    if(id){
      let completed = this.model.completed.find(x => x.id == id);
      let completedIndex = this.model.completed.indexOf(completed);
      this.model.completed.splice(completedIndex, 1);
      this.model.fullCount--;

      //console.log('removed media ', completed, ' index ', completedIndex);
      
      //Update status
      this.$model.next(this.model);

      let mediaObjects = this.mediaData.find(x => x.id == id);
      let mediaIndex = this.mediaData.indexOf(mediaObjects);
      this.mediaData.splice(mediaIndex, 1);

      this.$mediaData.next(this.mediaData);

      let fileObjects = this.fileData.find(x => x.id == id);
      let fileIndex = this.fileData.indexOf(fileObjects);
      this.fileData.splice(fileIndex, 1);

      this.$fileData.next(this.fileData);

      let packages = this.packageData.find(x => x.id == id);
      let packageIndex = this.packageData.indexOf(packages);
      this.packageData.splice(packageIndex, 1);
      
      this.$mediaPackager.next(this.packageData);

    } else { 
      // If no media id is passed, reset status fields

      this.fileData = [];
      this.mediaData = [];
      this.packageData = [];
      this.model = {
        processing: false,
        completed: [],
        queue: [],
        fullCount: 0
      }
      
      // Update status
      this.$mediaData.next(this.mediaData);
      this.$fileData.next(this.fileData);
      this.$model.next(this.model);
      this.$mediaPackager.next(this.packageData);

    }

  }

  //Processes images for image dimensions
  public processImage(image: any): Observable<ImageDimensions> {
    let img = new Image();
    img.src = image.target.result as string;

    return new Observable((val) => {
      img.onload = (evt) => {

        let dim: ImageDimensions = {
          width: img.width,
          height: img.height,
          dimension: img.width / img.height
        }

        val.next(dim);

      };

    });
  } 


  //Check for mime type
  private checkMimeType(file: File): Observable<any> {
    
    return new Observable((obs) => {
      ////console.log(file)
      FileType.fromBlob(file).then((res) => {
        //console.log('found mime');
        //console.log(res);
        obs.next(res);
        obs.complete();
      });
    });

  }

  private makeid(): string {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 32; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    };
    return text;
  }

  isIosSafari(): Boolean {
    let ua = window.navigator.userAgent;
    let iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
    let webkit = ua.match(/WebKit/i);
    let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari == null) {
        return false;
    } else {
        return true;
    } 
  }

}

// @Directive({
//   selector: '',
//   providers: [FileModelService]
// })

// export class FileModelDirective {
//   constructor(parameters) {
    
//   }
// }
