import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientRoot } from './core/http/http-client.service';
 
declare let gtag: Function;

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  constructor(private route: ActivatedRoute, private router: Router, private cookieService: CookieService, private base: HttpClientRoot) {
    this.router.events.subscribe((event) => {

      //track pageviews in analytics if not in test mode
      if (base.dev == false)
      {
        if(event instanceof NavigationEnd){
          gtag('config', 'UA-146943594-2', 
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      }
      
    });
   }

}
