import { AsyncSubject, BehaviorSubject, Observable } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators'; 
import { ComponentList, ComponentItem, dataToken } from '../core/models/Forms';


declare var $;


export interface NotificationObject {
  id?: string           //**DO NOT PASS!** Auto generated when options are passed. Used to track and dismiss note 
  text?: string,        //Main note text
  sub_text?: string,    //Sub text. Used as a discription for the main text
  type?: 'success' | 'error' | 'warn' | 'info',        //Type of note: 'success', 'error', 'warn', 'info'
  dismiss?: 'timer' | 'user',     //Dismiss type: 'timer' or 'user'; Timer dismisses in 3s, user waits for user action
  dismissed?: boolean
  link?: string         //Creates a link note that navigates to the provided route or url on click
}

export interface OverlayObject {
  components: Array<ComponentList>,
  active: ComponentItem
  options: OverlayOptions,
}

export interface OverlayOptions {
  pullTab: boolean, //enables mobile specific swipe gestures
  header: HeaderOptions,
  footer: FooterOptions 
}

export interface HeaderOptions {
  title?: string,
  stepper?: boolean,
  dismissable?: boolean
}

export interface FooterOptions {
  back?: ButtonOptions,
  next?: ButtonOptions,
  skip?: ButtonOptions,
  sumbit?: ButtonOptions,
  hide?: ButtonOptions
}

export interface ButtonOptions {
  icon?: string,
  label?: string,
  onClick: Function
}

export interface ConfirmDialogObject {
  id?: string,
  type: string, // type toggles yes no format vs custom interface. values can be: 'basic' or 'custom',
  title: string, // title text
  text: string, // body text
  sub_text?: string, //extra description
  cancel_btn_text?: string,
  confirm_btn_text?: string,
  confirmed: boolean // confirm box confirmed or canceled
  data?: any,
  data_type?: string
}


@Injectable({
  providedIn: 'root'
})

export class UiService {

  private header: BehaviorSubject<any> = new BehaviorSubject({});
  private _header: any = {};
  private notifications: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _notifications: Array<NotificationObject> = [];

  constructor(private router: Router) {
    router.events.pipe(filter(evt => evt instanceof NavigationEnd)).subscribe((evt: NavigationEnd) => {
      this._header.prevUrl = evt.url;
    });
  }

  private makeid(): string {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 32; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    };
    return text;
  }

  generateId(): string {
    return this.makeid();
  }

  updateHeader(options: any): void {

    //Example header object
    // {
    //   title: string,       Page Title
    //   icon: string,        Font awesome class: fas fa-home
    //   nav: true | false,   Optional: If back navigation is needed, set true
    //   prevUrl: string      DO NOT PASS! Previous route location for back navgation
    // }

    this.header.next({...this._header, ...options});
  }

  updateNotifications(options?: NotificationObject): void {

    //Example Note object
    // {
    //   id: string           **DO NOT PASS!** Auto generated when options are passed. Used to track and dismiss note 
    //   text: string,        Main note text
    //   sub_text: string,    Sub text. Used as a discription for the main text
    //   type: string,        Type of note: 'success', 'error', 'warn', 'info'
    //   dismiss: string,     Dismiss type: 'timer' or 'user'; Timer dismisses in 3s, user waits for user action
    //   link: string         Creates a link note that navigates to the provided route or url on click
    // }

    if(!options){
      this._notifications.filter(val => false);
      return
    }
    options.id = this.makeid();
    //console.log(options)
    this._notifications.push(options);
    this.notifications.next(options);
  }

  

  onHeaderChange: Observable<any> = this.header.asObservable();
  onNotificationChange: Observable<NotificationObject> = this.notifications.asObservable();

}


@Injectable({
  providedIn: 'root'
})


export class OverlayService {
  
  // public component: Observable<ComponentListItem> = this.$component.asObservable();
  private overlay: any = {
    active: false
  }

  private notice: BehaviorSubject<ConfirmDialogObject | null> = new BehaviorSubject(null); 
  private response: BehaviorSubject<any> = new BehaviorSubject(null);
  private $component: BehaviorSubject<ComponentItem> = new BehaviorSubject(null);
  private $response: BehaviorSubject<any> = new BehaviorSubject(null);
  private $overlay: BehaviorSubject<any> = new BehaviorSubject<any>(this.overlay);

  public tracker: Observable<any> = this.$overlay.asObservable();

  constructor(
    private router: Router
  ) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((res:NavigationEnd) => {
      this.clearOverlay();
    })
  }

  private makeid(): string {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 32; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    };
    return text;
  } 

  createNotice(option: ConfirmDialogObject): void { 
    option.id = this.makeid();
    this.notice.next(option); 
    //this.response.next(null);
  }
  
  updateResponse(id:string, confirmed: any): void {

    let note = this.notice.getValue();
    if (note.id == id){ 
      this.response.next(confirmed);
    }
  }

  setOverlay(component: ComponentItem, stretch?: boolean): Observable<any>{
    if(this.overlay.active){
      return
    }
    this.overlay = component;
    this.overlay.active = true;
    this.overlay.toClose = false;
    this.overlay.stretch = stretch;
  
    this.overlay.update = this.$component;
    
    this.$component.next(component);
    this.$overlay.next(this.overlay);
    return component.getInjector().get(dataToken).responder.asObservable();
  }

  clearOverlay(){
    this.overlay.active = false;
    this.overlay.toClose = true;
    this.overlay.component = null;
    this.overlay.stretch = false;
    this.$component.next(null);
    this.$overlay.next(this.overlay);
  }

  onNoticeChange: Observable<ConfirmDialogObject> = this.notice.asObservable();
  onResponseChange: Observable<any | null> = this.response.asObservable();
}

export interface Actions {
  actions: Action[]
  collapsable: boolean
}

export interface Action {
  name: string,
  icon: string,
  role: 'primary' | 'secondary',
  type: string,
  link: string,
  action_id: string,
}

export interface ActionRef {
  action: string
  id: string
}

@Injectable({
  providedIn: 'root'
})

export class FloatingActionService {

  private actions: BehaviorSubject<Actions> = new BehaviorSubject({actions: [], collapsable: false});
  private onAction: BehaviorSubject<ActionRef> = new BehaviorSubject(null);
  private collapsable: boolean = false;

  //Defualt action is to create a new post
  private defualt = { 

    //List of actions
    actions : [ 
      {
        name: 'new post',         //Used as the button label
        icon: 'fas fa-paper-plane',   //Fontawsome icon to be used
        role: 'primary',          //Button priority: 'primary' and 'secondary' 
        type: 'link',             //Type of action: 'link' or 'action'
        link: '/new',             //link must be defined for 'link' type
                                  //Used in routerLink to navigate to link
        action_id: '0',           //action id must be defined for 'action' type
                                  //Used to track what action is being triggered.
                                  //Sibling/child component must store and validate the
                                  //action id and name to run appropiate behaviors.
                                  //View /interest-feed/interest-feed.component.ts:
      }
    ],
    collapsable: false            //If actions array is greater than one, 
                                  //it will defualt to collapsible FAB.
                                  //Can be over-ridden by passing true as the
                                  //second parameter when adding actions.
  }
  
  sharedActions: Observable<Actions> = this.actions.asObservable();
  onActionEvent: Observable<ActionRef> = this.onAction.asObservable();

  constructor() {
    //Todo: Check for user type and set default actions;
  }

  setActions(actions?: Array<any>, over_ride?: boolean): Observable<any> {
    this.collapsable = (actions.length > 1)? true: false;
    this.actions.next({actions: actions,  collapsable: (over_ride)? false: this.collapsable});
    return this.onActionEvent
  }

  triggerAction(action_id: string, action_name: string): void {
    this.onAction.next({action: action_name, id: action_id});
  }

  clearTrigger(): void {
    this.onAction.next(null);
  }

  reset(empty?: boolean): void {

    if (empty){
      this.actions.next({actions: [], collapsable: false});
      this.clearTrigger();
      return;
    }

    this.clearTrigger();
    this.setActions(this.defualt.actions, false);
        
  }


}
