
import { AsyncSubject, BehaviorSubject, Observable } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from "ngx-cookie-service";

@Injectable({
    providedIn: 'root'
})
export class PlatformStateService {
 
    platformEnableDevMode: boolean = true;

    public isIos: boolean;
    public isLoggedIn: boolean;
    cookieService: any;
    logged_user: string = "";

    constructor(cookies: CookieService){ 
        this.isIos = this.isIosSafari();
        this.cookieService = cookies;
        this.platformEnableDevMode = true;
    }

    checkLoggedIn(): boolean {
        let userid = this.cookieService.get('user_id');
        if (userid === undefined || userid === '') {
            return false;
        }

        this.logged_user = userid;
        return true;
    }

    getLoggedInUser(): string {
        let userid = this.cookieService.get('user_id');
        if (userid === undefined || userid === '') {
            return "";
        } 

        return this.logged_user = userid;;
    }

    isIosSafari(): boolean {
        let ua = window.navigator.userAgent;
        let iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
        let webkit = ua.match(/WebKit/i);
        let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    
        if (iOSSafari == null) {
            return false;
        } else {
            return true;
        } 
    }
}

